<template>
    <div class='editIndex'>
      <CreateAndEdit :id="id"  />
  
    </div>
  </template>
  
  <script>
  import CreateAndEdit from './components/CreateAndEdit.vue'
  
  export default {
    name: 'editIndex',
    components: {
      CreateAndEdit
    },
    props: {
      id: {
        type: [String,Number],
        required: true
      }
    },
    data () {
      return {
  
      }
    },
    mounted () {
        // this.userId = this.$route.params.userId
        // console.log('this.userId')
        // console.log(this.userId)
    },
    methods: {
  
    }
  }
  </script>
  <style lang='scss' scoped>
  
  </style>
  